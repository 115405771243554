import React, { useState, useEffect, createContext } from "react";
import useLocaleStorage from "../hooks/useLocalStorage";
type TLocale = "de" | "fr" | "en";

interface ContextProps {
    readonly locale: TLocale;
    readonly setLocale: (locale: TLocale) => void;
}

export const LocaleContext = createContext<ContextProps>({
    locale: "fr",
    setLocale: () => null,
});

export const LocaleProvider: React.FC = ({ children }) => {
    const [localStorageLocale, setLocalStorageLocale] =
        useLocaleStorage("locale");
    const [locale, setLocale] = useState(localStorageLocale || "fr");

    useEffect(() => {
        const item = window?.localStorage.getItem("locale");
        const value = item || "fr";
        if (locale !== value) {
            window?.localStorage.setItem("locale", JSON.stringify(locale));
        }
    }, [locale]);

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            {children}
        </LocaleContext.Provider>
    );
};
