import React, { useEffect } from "react";
import "../styles/main.sass";
import useLocaleStorage from "../hooks/useLocalStorage";
import { LocaleProvider } from "../context/LocaleContext";

function App({ Component, pageProps }) {
    useEffect(() => {
        const cookie = document.createElement("script");
        cookie.src = "/scripts/CookieBanner.js";
        cookie.async = true;
        cookie.type = "module";
        document.body.appendChild(cookie);
        const script = document.createElement("script");
        script.src = "/scripts/scripts.js";
        script.async = true;
        script.type = "module";
        document.body.appendChild(script);
    }, []);

    return (
        <LocaleProvider>
            <Component {...pageProps} />
        </LocaleProvider>
    );
}

export default App;
